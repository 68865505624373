import React, { Component, useEffect } from "react";
import { Link } from 'gatsby'
import { Wrapper } from '../components/site/Wrapper';
import { Grid, Card, Image, Text, Badge, Button, Group, AspectRatio } from '@mantine/core';
import { useNavigate } from "@gatsbyjs/reach-router";
import ShortQR from '../components/ShortQR';
import { useMediaQuery } from "@mantine/hooks";

export const Category = ({ pageContext, limit, navigate, ...props }) => {
    useEffect(() => {
        console.log('props', pageContext, props);
    }, []);
    
    // const navigate = useNavigate();

    useEffect(()=>{
        if(navigate){
            navigate('/books', {replace: true})
        }
        
    }, [navigate])

    return (
        null
    )
}

const ComponentWrapper =  ({ pageContext, ...props }) => {
    return <Wrapper current={pageContext.node.name}>
        <Category pageContext={pageContext}
            {...props} />
    </Wrapper>
}

export default ComponentWrapper;